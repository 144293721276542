import {
  SymplBreadcrumb,
  SymplFooter,
} from '@symplr-ux/alloy-components/dist/react-bindings';
import './App.scss';
import { type ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from './AppHeader/AppHeader';

function App(): ReactElement[] {
  const breadcrumbItems = [
    {
      key: 'a',
      text: 'Lorem Ipsum',
    },
    {
      key: 'b',
      text: 'Lorem Ipsum',
    },
    {
      key: 'c',
      text: 'Lorem Ipsum',
    },
    {
      key: 'd',
      text: 'Lorem Ipsum',
    },
    {
      key: 'e',
      text: 'Lorem Ipsum',
    },
    {
      key: 'f',
      text: 'Lorem Ipsum',
    },
  ];

  return [
    <AppHeader key="app-header"></AppHeader>,
    <section key="app-main" className="app-main">
      <section className="app-content">
        <SymplBreadcrumb
          items={breadcrumbItems}
          maxDisplayedItems={6}></SymplBreadcrumb>
        <main className="py-3">
          <Outlet></Outlet>
        </main>
      </section>
      <footer>
        <SymplFooter year="2023"></SymplFooter>
      </footer>
    </section>,
  ];
}

export default App;
