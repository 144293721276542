import './Home.scss';
import { type ReactElement } from 'react';

function Home(): ReactElement[] {
  const href = 'https://github.com/symplr-software/ce-alloy-react-demo';
  return [
    <h1 key="home-title">Home page</h1>,
    <p key="home-message">
      Code for this application can be found <a href={href}>here</a>.
    </p>,
  ];
}

export default Home;
