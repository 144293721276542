import {
  SymplAccountMenu,
  SymplHeader,
  SymplMenu,
  SymplSearchBar,
  SymplUtilityBar,
} from '@symplr-ux/alloy-components/dist/react-bindings';
import './AppHeader.scss';
import { useState, type ReactElement } from 'react';
import type { ISymplMenuItem } from '@symplr-ux/alloy-components';
import { useNavigate } from 'react-router-dom';

function AppHeader(): ReactElement {
  const [activeRoute, setActiveRoute] = useState('home');
  const menuItems = [
    {
      key: 'home',
      text: 'Home',
    },
    {
      key: 'templates',
      text: 'Templates',
      subMenuProps: [
        {
          key: 'templates/form',
          text: 'Form',
        },
      ],
    },
  ] as ISymplMenuItem[];
  const navigate = useNavigate();

  function go(ev: CustomEvent<string | number>): void {
    const route = ev.detail.toString();
    setActiveRoute(route);
    navigate(route);
  }

  return (
    <SymplHeader className="app-header" logo="assets/logo.svg">
      <SymplMenu
        slot="menu"
        items={menuItems}
        onSelected={ev => {
          go(ev);
        }}
        activeItem={activeRoute}></SymplMenu>
      <SymplSearchBar slot="search"></SymplSearchBar>
      <SymplUtilityBar slot="utility"></SymplUtilityBar>
      <SymplAccountMenu slot="account"></SymplAccountMenu>
    </SymplHeader>
  );
}

export default AppHeader;
