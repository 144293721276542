/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './FormTemplate.scss';
import { useRef, type ReactElement, useState } from 'react';
import {
  SymplCard,
  SymplDatepicker,
  SymplDialog,
  SymplDropdown,
  SymplEmailField,
  SymplNumberField,
  SymplPhoneField,
  SymplPrimaryButton,
  SymplSecondaryButton,
  SymplSeparator,
  SymplTertiaryButton,
  SymplTextArea,
  SymplTextField,
  SymplZipField,
} from '@symplr-ux/alloy-components/dist/react-bindings';

/**
 * This form demonstrates a form with controlled components
 * @see {@link https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components | Controlled and Uncontrolled Components}
 */
function FormTemplate(): ReactElement[] {
  const [formData, setFormData] = useState({
    id: undefined,
    fullName: '',
    postNominalLetters: '',
    firstName: '',
    middleName: '',
    lastName: '',
    ssn: '',
    dob: undefined,
    email: '',
    cell: '',
    cellExt: '',
    phone: '',
    phoneExt: '',
    address: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    preferredWorkingHours: undefined,
    jobTitle: 'Senior Awesome Person',
    supervisor: 'Magnificent Manager',
    department: 'The Greatest Department',
    biography: '',
  });
  const dialogRef = useRef(null);
  const states = [
    { key: 'AL', text: 'Alabama' },
    { key: 'AK', text: 'Alaska' },
    { key: 'AZ', text: 'Arizona' },
    { key: 'AR', text: 'Arkansas' },
    { key: 'CA', text: 'California' },
    { key: 'CO', text: 'Colorado' },
    { key: 'CT', text: 'Connecticut' },
    { key: 'DE', text: 'Delaware' },
    { key: 'FL', text: 'Florida' },
    { key: 'GA', text: 'Georgia' },
    { key: 'HI', text: 'Hawaii' },
    { key: 'ID', text: 'Idaho' },
    { key: 'IL', text: 'Illinois' },
    { key: 'IN', text: 'Indiana' },
    { key: 'IO', text: 'Iowa' },
    { key: 'KS', text: 'Kansas' },
    { key: 'KY', text: 'Kentucky' },
    { key: 'LA', text: 'Louisiana' },
    { key: 'ME', text: 'Maine' },
    { key: 'MD', text: 'Maryland' },
    { key: 'MA', text: 'Massachusetts' },
    { key: 'MI', text: 'Michigan' },
    { key: 'MN', text: 'Minnesota' },
    { key: 'MS', text: 'Mississippi' },
    { key: 'MO', text: 'Missouri' },
    { key: 'MY', text: 'Montana' },
    { key: 'NE', text: 'Nebraska' },
    { key: 'NV', text: 'Nevada' },
    { key: 'NH', text: 'New Hampshire' },
    { key: 'NJ', text: 'New Jersey' },
    { key: 'NM', text: 'New Mexico' },
    { key: 'NY', text: 'New York' },
    { key: 'NC', text: 'North Carolina' },
    { key: 'ND', text: 'North Dakota' },
    { key: 'OH', text: 'Ohio' },
    { key: 'OK', text: 'Oklahoma' },
    { key: 'OR', text: 'Oregon' },
    { key: 'PA', text: 'Pennsylvania' },
    { key: 'RI', text: 'Rhode Island' },
    { key: 'SC', text: 'South Carolina' },
    { key: 'SD', text: 'South Dakota' },
    { key: 'TN', text: 'Tennessee' },
    { key: 'TX', text: 'Texas' },
    { key: 'UT', text: 'Utah' },
    { key: 'VT', text: 'Vermont' },
    { key: 'VA', text: 'Virginia' },
    { key: 'WA', text: 'Washington' },
    { key: 'WV', text: 'West Virginia' },
    { key: 'WI', text: 'Wisconsin' },
    { key: 'WY', text: 'Wyoming' },
  ];

  /**
   * Each control emits a symplvalue event, the event is captured and the form is updated.
   * Exact implementation will vary based on product, framework, or third-party plugins used.
   * This approach can be similarly followed for the symplvalidation event.
   * @param value The value emitted by the symplvalue event.
   * @param formKey The name of the form field, used to updated the formData.
   */
  const handleChange = (value: any, formKey: string): void => {
    setFormData(prevFormData => ({ ...prevFormData, [formKey]: value }));
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    void (dialogRef.current as unknown as HTMLSymplDialogElement).open();
  };

  return [
    <section key="form-example" className="sympl-grid">
      <h1 className="sympl-col-12">Top of Screen Header</h1>
      <p className="sympl-col-12 md:sympl-col-10 lg:sympl-col-8">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi corrupti
        totam ex, molestias quo commodi libero enim, in debitis ratione, cumque
        repellendus esse corporis distinctio vel similique rem ipsum illo
        dolore? Sed cum assumenda totam error ad possimus quisquam ipsam, ipsa
        mollitia doloremque eum voluptates facilis quam dicta amet culpa eaque
        cupiditate illum! Eius natus ab laudantium corrupti nobis nisi obcaecati
        ullam doloribus, sunt aliquid aspernatur aliquam omnis totam nihil, ipsa
        neque maiores, culpa earum porro molestiae inventore qui error placeat?
        Molestias provident nam architecto nobis distinctio! Adipisci eaque
        vitae corporis dignissimos, consequuntur nostrum iste ut sed nemo,
        recusandae vero.
      </p>

      <form onSubmit={handleSubmit}>
        <SymplCard className="sympl-col-12" full-width>
          <section className="sympl-grid sympl-grid-nogutter nested-grid-content">
            <div
              className="side-panel sympl-col-fixed"
              style={{ width: '240px' }}>
              <ul className="side-panel-nav">
                <li className="heading-sm">Information</li>
                <li>
                  <a className="sympl-link">Personal information</a>
                </li>
                <li>
                  <a className="sympl-link">Contact information</a>
                </li>
                <li className="heading-sm">Preferences</li>
                <li>
                  <a className="sympl-link">Organization information</a>
                </li>
                <li className="heading-sm">Additional details</li>
                <li>
                  <a className="sympl-link">Biography</a>
                </li>
              </ul>
              <SymplSeparator type="vertical"></SymplSeparator>
            </div>
            <div className="form-panel sympl-col">
              <div className="sympl-grid">
                <span className="sympl-col-12 heading-sm">Information</span>
                <span className="sympl-col-12 body-lg" id="personal">
                  Personal information
                </span>
                <SymplNumberField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="ID number"
                  value={formData.id}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'id');
                  }}
                  required></SymplNumberField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="Full name"
                  value={formData.fullName}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'fullName');
                  }}
                  required></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="Post-nominal letters"
                  value={formData.postNominalLetters}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'postNominalLetters');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="First name"
                  value={formData.firstName}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'firstName');
                  }}
                  required></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="Middle name"
                  value={formData.middleName}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'middleName');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="Last name"
                  value={formData.lastName}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'lastName');
                  }}
                  required></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Social security number"
                  mask="999-99-9999"
                  maskChar="_"
                  value={formData.ssn}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'ssn');
                  }}
                  required></SymplTextField>
                <SymplDatepicker
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Date of birth"
                  value={formData.dob}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'dob');
                  }}
                  required></SymplDatepicker>
                <p className="sympl-col-12 body-lg" id="contact">
                  Contact information
                </p>
                <SymplEmailField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-4"
                  label="Email address"
                  value={formData.email}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'email');
                  }}
                  required></SymplEmailField>
                <SymplPhoneField
                  className="sympl-col-12 md:sympl-col-4 lg:sympl-col-3"
                  label="Cell number"
                  value={formData.cell}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'cell');
                  }}
                  required></SymplPhoneField>
                <SymplTextField
                  className="sympl-col-3 md:sympl-col-2 lg:sympl-col-1"
                  label="Ext."
                  value={formData.cellExt}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'cellExt');
                  }}></SymplTextField>
                <SymplPhoneField
                  className="sympl-col-12 md:sympl-col-4 lg:sympl-col-3"
                  label="Phone number"
                  value={formData.phone}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'phone');
                  }}
                  required></SymplPhoneField>
                <SymplTextField
                  className="sympl-col-3 md:sympl-col-2 lg:sympl-col-1"
                  label="Ext."
                  value={formData.phoneExt}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'phoneExt');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Address"
                  value={formData.address}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'address');
                  }}
                  required></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Address 2"
                  value={formData.addressLine2}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'addressLine2');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-6 md:sympl-col-6 lg:sympl-col-2"
                  label="City"
                  value={formData.city}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'city');
                  }}
                  required></SymplTextField>
                <SymplDropdown
                  className="sympl-col-6 md:sympl-col-6 lg:sympl-col-2"
                  options={states}
                  label="State"
                  value={formData.state}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'state');
                  }}
                  required></SymplDropdown>
                <SymplZipField
                  className="sympl-col-6 md:sympl-col-6 lg:sympl-col-2"
                  label="ZIP code"
                  value={formData.zip}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'zip');
                  }}
                  required></SymplZipField>
                <SymplSeparator className="sympl-col-12 form-section-divider"></SymplSeparator>
                <span className="sympl-col-12 heading-sm">Preferences</span>
                <span className="sympl-col-12 body-lg" id="organization">
                  Organization information
                </span>
                <SymplDatepicker
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Preferred working hours"
                  placeholder="MM/dd/yyyy hh:mm:ss AM"
                  hourFormat="12"
                  dateTimeFormat="'MM/dd/yyyy hh:mm:ss a'"
                  showTime
                  showSeconds
                  value={formData.preferredWorkingHours}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'preferredWorkingHours');
                  }}
                  required></SymplDatepicker>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Job title"
                  read-only
                  value={formData.jobTitle}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'jobTitle');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Supervisor"
                  read-only
                  value={formData.supervisor}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'supervisor');
                  }}></SymplTextField>
                <SymplTextField
                  className="sympl-col-12 md:sympl-col-6 lg:sympl-col-3"
                  label="Department"
                  read-only
                  value={formData.department}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'department');
                  }}></SymplTextField>
                <SymplSeparator className="sympl-col-12 form-section-divider"></SymplSeparator>
                <span className="sympl-col-12 heading-sm">
                  Additional details
                </span>
                <SymplTextArea
                  id="biography"
                  className="sympl-col-12 md:sympl-col-12 lg:sympl-col-6"
                  label="Biography"
                  rows={5}
                  maxchar={200}
                  value={formData.biography}
                  onSymplvalue={ev => {
                    handleChange(ev.detail, 'biography');
                  }}></SymplTextArea>
              </div>
            </div>
          </section>
        </SymplCard>
        <div className="form-template-buttons">
          <SymplTertiaryButton text="Clear" type="reset"></SymplTertiaryButton>
          <SymplSecondaryButton text="Cancel"></SymplSecondaryButton>
          <SymplPrimaryButton text="Done" type="submit"></SymplPrimaryButton>
        </div>
      </form>
    </section>,
    <SymplDialog key="form-dialog" ref={dialogRef}>
      <div slot="content">
        <pre>{JSON.stringify(formData, null, 2)}</pre>
      </div>
    </SymplDialog>,
  ];
}

export default FormTemplate;
